import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const CustomAlert = forwardRef((props, ref) => {
  const [duration, setDuration] = useState(0);
  const [visible, setVisible] = useState(false);
  const [messageObj, setmessageObj] = useState({type: "", description: ""});

  let timeOutId;

  useImperativeHandle(ref, () => ({setAlert}));

  useEffect(() => {
    return () => {
      clearInterval(timeOutId)
    }
  }, [])

  const setAlert = ( type, description ) => {
    if(!type || !description) return;
    setDuration(3);
    setmessageObj({type, description});
    setVisible(true);

    callForClose();
  }

  const callForClose = () => {
    timeOutId = setTimeout(() => {
      closeWarning();
    }, 3000);
  }

  const closeWarning = () => {
    setmessageObj({});
    setVisible(false);
  }

  return (
    <>
      {visible && (
        <div className={`toaster ${messageObj?.type == "success" ? 'success-bg' : 'warning-bg'}`}>
          <div className="toaster_T">
            <div className="toaster-icon">
              {messageObj?.type == "success" ? <img src="/icons/successIcon.svg" alt="Warning Icon" />
              : <img src="/icons/warningIcon.svg" alt="Warning Icon" />}
            </div>
            <div className="toaster-content">
              <p className="toaster-title">{messageObj?.type}</p>
            </div>
            <h4 className="toaster-close mb-0" onClick={closeWarning}>&times;</h4>
          </div>
          <h6 className="toaster-description">{messageObj?.description}</h6>
        </div>
      )}
     
    </>
  );
});

export default CustomAlert;
