import React from 'react';
import Invalid from '../../components/Account/Invalid';
import HeaderImage from '../../components/Account/HeaderImage';
import { useLocation } from 'react-router-dom';

const InvalidPage = ( props ) => {
    const { state } = useLocation();

    return (
    <div id='invalid'>
    <div className='headerImg ps-2'>
        <HeaderImage />
    </div>
    <div className='invalidPage' >
        <Invalid message={state ?? "Invalid Link"} />
    </div>
    </div>)
}

export default InvalidPage;