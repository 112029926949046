import React, { useEffect, useRef, useState } from 'react'

function TextToImage(props) {
    const [ img, setImage ] = useState('');
    const canvasRef = useRef(null);
    useEffect(()=>{
        if(props.name !== null && canvasRef.current){
            let canvasTxt = canvasRef.current.getContext("2d");
            canvasTxt.canvas.width = canvasTxt.measureText(props.name).width;
            canvasTxt.canvas.height = 15;
            // canvasTxt.canvas.height = canvasTxt.measureText(props.name).height;
            canvasTxt.font = `18px Verdana`;
            canvasTxt.fillStyle = "#76272C";
            canvasTxt.fillText(props.name, props.x, props.y);
            let imageUrl = canvasTxt.canvas.toDataURL();
            setImage(imageUrl)
        }
    },[props.name])

    return (
      < div className = 'flex-grow-1' >
        <canvas id="canvasComponent d-none" style={{display: 'none'}} ref={canvasRef}  />
        {
          img !== null ?
          <div className='m-0 form-control text-center' style={{width:'100px'}}>
            <img className='' src={img} />
          </div>
          : null}
      </div>
    );
}

export default TextToImage