import React, { useState, useEffect } from 'react'
import commonLib from '../control/commonLib';
import konsole from '../control/Konsole';
import Header from './HeaderImage'
import toast, { Toaster } from 'react-hot-toast'

import { useLocation, useNavigate } from 'react-router-dom';
import { AoPartnerBaseUrl } from '../network/UrlPath';
import HeaderImage from './HeaderImage';

function SelectRoles(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectroleId, setselectRoleId] = useState(null);
  const [showErrMessage, setShowErrMessage] = useState(false);
  const selectedRolesData = state?.selectedRolesData;

  const selectedRoles = selectedRolesData?.selectedRoles;
  const [showHearAboutUsDropdown, setShowHearAboutUsDropdown] = useState(false);

  const auhtenicateKeys = selectedRolesData?.auhtenicateKeys;

  const [selectedRole, setSelectedRole] = useState('');

  const handleRoleSelect = (roleId, roleKey) => {
    setSelectedRole(roleKey);
    setselectRoleId(roleId);
    setShowHearAboutUsDropdown(false);
    setShowErrMessage("");
  };

  // useEffect(() => {
  //   let timeout;

  //   if (showErrMessage) {
  //     toast.error("Please select a role.");
  //     timeout = setTimeout(() => {
  //       setShowErrMessage(false);
  //     }, 4000);
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [showErrMessage]);


  // const handleChange = (event) => {
  //   konsole.log("event", event.target.value)
  //   setselectRoleId(event.target.value);
  // }


  const handleClick = () => {
    const roleId = selectroleId;
    const appState = auhtenicateKeys.appState;
    const userId = auhtenicateKeys.userId;
    const loggenInId = auhtenicateKeys.loggenInId;
    if (roleId !== '' && roleId !== null && roleId !== undefined) {
      // setShowErrMessage(false);
      if (roleId == 20 || roleId == 9 || roleId == 17 || roleId == 5 || roleId == 18) {
        let userPswd = localStorage.getItem('|\/*P*/\|')
        let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(selectedRoles)}&loggenInId=${loggenInId}&Psw=${userPswd}`;
        window.location.replace(`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`, "_blank");
      }
      else {
        let tokenKey = `appState=${appState}&userId=${userId}&roleId=${roleId}&loggenInId=${loggenInId}`;
        commonLib.redirectToModule(roleId, tokenKey);
      }
    } else {
      // toast.error("Please select a role");
      setShowErrMessage("Please select a role");
    }
  }


  konsole.log("selectedRolesselectedRoles", selectedRoles)
  const cancelmodaltosignin = () => {
    navigate('/Account/SignIn')
  }

  return (
    <div id='selectRole'>
      <div className='headerImg'>
        <HeaderImage />
      </div>
   
      <div className="vertically-center9">
     
      <p className='title text-center'>Welcome to the Portal</p>
      {selectedRoles.length > 0 && (
        <div className="label-input">
          <label>Please pick a role for this visit</label>
          <div
            className="row selectBorderHearAboutUs justify-content-between m-0"
            onClick={() => setShowHearAboutUsDropdown(!showHearAboutUsDropdown)}
          >
            <div className="col-10 p-0 hearAboutUsLeftCol">
              <span className={selectedRole ? 'selectedRole' : ''}>{selectedRole || 'Select role'}</span>
            </div>
            <div className="col-1 d-flex justify-content-center hearAboutUsRightCol">
              <img src="/icons/hearAboutOption.svg" alt="dropdown icon" />
            </div>
          </div>
          <p className='attention'>{showErrMessage}</p>

          {showHearAboutUsDropdown && (
            <div className="d-flex justify-content-end mt-3">
              <div className="hearAbout p-2">
                <div className="">
                  {selectedRoles.map((data, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleRoleSelect(data.roleId, data.roleKey)}
                    >
                      {data.roleKey}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        
      )}
      </div>
      <div className="label-input">
        <button type="button" onClick={handleClick}>Continue</button>
      </div>
    </div>
  )
}

export default SelectRoles