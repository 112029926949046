import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SessionTimeout = () => {
    const [openTimeoutModal, setOpenTimeoutModal] = useState(true)
    const navigate = useNavigate();

    const handleOkayNow = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('expired');
        navigate(url.toString(), { replace: true });
        setOpenTimeoutModal(false)
    }

  return (
    <>
        <div id="idleTimerModal" show={openTimeoutModal} role="dialog" aria-modal="true" class="fade customModal modal show" tabindex="-1" style={{display:'block'}}>
            <div id="idleTimerModal" class="modal-dialog">
                <div class="modal-content">
                    <div closeButton className="d-flex justify-content-between border-0 modal-header">
                        <button type="button" class="btn-close" aria-label="Close" onClick={handleOkayNow}></button>
                    </div>
                    <div className='text-center modal-body'>
                        <div className='d-flex d-flex-col justify-content-center'>
                            <div className='d-flex-row idleTimerClock'>
                                <img src='/icons/sessionTimeout.svg' />
                            </div>
                        </div>
                        <div className='d-flex-col idleTimerContent'>
                            <div className='d-flex-row'>
                                <h1>Session Timeout</h1>
                            </div>
                            <div className='d-flex-row'>
                                <p className='pt-0'>You have been logged out due to inactivity.</p>
                            </div>
                        </div>
                        <div className='d-flex-col idleTimerButton'>
                            <div className='d-flex-row'>
                                <div className='d-flex-col'>
                                    <button
                                        onClick={handleOkayNow}
                                        className='active'
                                    >
                                        Ok, thanks
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SessionTimeout;