import axios from "axios";
import commonLib from "../control/commonLib";
import konsole from "../control/Konsole";
import { $Service_Url } from "./UrlPath";



export let $CommonServiceFn = {
    InvokeCommonApi: function (method, url, inputdata, callback) {
        //return function (dispatch) {
        let token = commonLib.getSessionStorage("AuthToken") || "";
        konsole.log("Req url " + url);
        // konsole.log("Token " + token),
            // konsole.log("Input " + JSON.stringify(inputdata));

        //dispatch({ type: SET_LOADER, payload: true });
        axios({
            headers: { 'Access-Control-Allow-Origin': true, "Authorization": `Bearer ${token}` },
            config: { headers: { 'Content-Type': 'application/json' } },
            method: method,
            url: url,
            data: inputdata
        })
        .then(data => {
            if (callback) {
                konsole.log("URL-", url,data);
                callback(data,null);
            }
        })
        .catch(error => {
            let errordata = null;
            konsole.log("Error ",error + url)
            konsole.log("Error ", error.response)
            if (error.response) {
                errordata = error.response;
                if (errordata.status == 401) {
                    sessionStorage.setItem("AuthToken","");
                }
                // konsole.log(errordata);
                if (errordata.status !== 401 && errordata.status == 404) {
                    if (callback) {
                        return callback(null,errordata);
                    }
                }
                if (errordata.status !== 401 && errordata.status == 400) {
                    if (callback) {
                        return callback(null,errordata);
                    }
                }
                if (errordata.status !== 401 && errordata.status == 503) {
                    konsole.log("Something went Wrong. Try again later.");
                }
                if (callback) {
                    return callback(null, errordata);
                }
            }
        });
    },
    InvokeCommonMultpartApi: function (method, url, inputdata, callback) {
        //return function (dispatch) {
        // let token = commonLib.getSessionStorage("AuthToken") || "";
        konsole.log("Req url " + url);
        // konsole.log("Token " + token),
        // konsole.log("Input " + JSON.stringify(inputdata));

        //dispatch({ type: SET_LOADER, payload: true });
        axios({
            headers: {
                crossDomain: true
            },
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', crossDomain: true
} },
            method: method,
            url: url,   
            data: inputdata
        })
            .then(data => {
                if (callback) {
                    konsole.log("URL-", url, data);
                    callback(data, null);
                }
            })
            .catch(error => {
                let errordata = null;
                konsole.log("Error ", error + url)
                konsole.log("Error ", error.response)
                if (error.response) {
                    errordata = error.response;
                    if (errordata.status == 401) {
                        // sessionStorage.setItem("AuthToken", "");
                    }
                    // konsole.log(errordata);
                    if (errordata.status !== 401 && errordata.status == 404) {
                        if (callback) {
                            callback(null, errordata);
                        }
                    }
                    if (errordata.status !== 401 && errordata.status == 400) {
                        if (callback) {
                            callback(null, errordata);
                        }
                    }
                    if (errordata.status !== 401 && errordata.status == 503) {
                        konsole.log("Something went Wrong. Try again later.");
                    }
                    if (callback) {
                        callback(null, errordata);
                    }
                }
            });
    },
    InvokeCommonApiForLeadStack:function(jsonObj,callback){
        let token='6606a0c3-2cbf-4a3a-b12d-484c15559f61'
        let method='POST'
        let url='https://rest.gohighlevel.com/v1/contacts/'
        axios({
            headers: { 'Access-Control-Allow-Origin': true, "Authorization": `Bearer ${token}` },
            config: { headers: { 'Content-Type': 'application/json' } },
            method: method,
            url: url,
            data: jsonObj
        })
        .then(data => {
            if (callback) {
                konsole.log("URL-", url,data);
                callback(data,null);
            }
        })
        .catch(error => {
            let errordata = null;
            konsole.log("Error ",error + url)
            konsole.log("Error ", error.response)
            if (error.response) {
                errordata = error.response;
                if ((errordata.status == 400 || errordata.status == 404)  && callback ) {
                        return callback(null,errordata);
                    
                }
                if (errordata.status !== 401 && errordata.status == 503) {
                    konsole.log("Something went Wrong. Try again later.");
                }
                if (callback) {
                    return callback(null, errordata);
                }
            }
        });
    },
}

