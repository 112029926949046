import React from 'react';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import konsole from '../../components/control/Konsole';
import HeaderImage from '../../components/Account/HeaderImage';

export default function SuccessResetPassword() {
    const navigate = useNavigate();
    const location = useLocation().state;
    konsole.log("location state", location)

    return (
           <>
       <div id='sucessReset'>
      <div className='headerImg'>
        <HeaderImage />
      </div>
      
       
       <div className="vertically-center6">
       <div className='mssgeMainDiv'>
      <img src="/icons/verifiedCheck.svg"/>
      </div>
        <p className='title text-center'>{location == "Reset" ? "Password reset" : "Set password"}</p>
        <p className='sub-title text-center'>Your password has been successfully {location == "Reset" ? "reset" : "created"}.</p>
       

       <NavLink className="label-input backsignUp" to="/Account/SignIn">
          <button type="submit">Back to login</button>
        </NavLink>
        </div>
        </div>

      
      
        </>

      
        // <div className="container-fluid bg-light" style={{ height: 'auto', minHeight: '100vh' }}>
        //     <div className="row">
        //         <div className='col-sm-12 col-md-8 col-lg-4 bg-white rounded dased-border pt-2 pb-3 mt-3 m-auto text-center my-3'>
        //             <Header />
        //             <h1 className='congratulationText mt-4'>Congratulations</h1>
        //             <p className='mt-2 fs-5'>Your Password has been {location} successfully.</p>
        //             <p className='fs-5'> Click below to Sign In.</p>
        //             <div className="align-item-center mt-3">
        //                 <button type="submit" className="theme-btn" onClick={() => navigate('/Account/SignIn')}>Sign In</button>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='col-sm-12 col-md-8 col-lg-4 m-auto' >
        //         <Footer />
        //     </div>
        // </div>

    );
};