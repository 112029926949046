import React, { useState, useEffect} from 'react';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { Link, useNavigate } from "react-router-dom";
import commonLib from '../../components/control/commonLib';

const Page = () => {
  const [subtenantId,setSubtenantId] = useState(0)
  const navigate =  useNavigate();

  let subtenantDetails = sessionStorage?.getItem('subtenantDetails')
  useEffect(()=>{
    navigate('/Account/SignIn')
},[])
  useEffect(()=>{

    let subtenantId = commonLib.getQueryFromUrl("subtenantId");
    if (subtenantId) {
      setSubtenantId(subtenantId);
    }
    else if (subtenantDetails !== null) {
      setSubtenantId(JSON.parse(subtenantDetails).subtenantId)
    }
    else {
      setSubtenantId(null);
    }
    },[subtenantDetails])
  return (
    <div>
      <div className="container-fluid bg-light pt-5" style={{height: 'auto', minHeight: '100vh'}}>
        <div className="col-sm-12 col-md-8 col-lg-4 m-auto">
          <div className="row dased-border bg-white p-0 m-0 d-flex justify-content-center">
            <div className="col-sm-12  bg-white m-auto rounded  error px-4">
              <Header />
              <img src="../icons/pana.png" alt="404 Error" className="mt-5"/>
            </div>
            <div className="d-flex py-3 mt-5 fs-4 justify-content-center">
              <p>
                Click here to
                <Link to='/Account/Signin' className="theme-heading text-decoration-underline"> Sign In</Link> (or) {" "} {subtenantId == 3 ?<Link className="theme-heading text-decoration-underline" to={`/Account/startfreetrial?subtenantId=${subtenantId}`}> Sign Up</Link> :<Link className="theme-heading text-decoration-underline" to='/Account/Signup'> Sign Up</Link>} 
                <strong className="text-primary"></strong>  
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-4 m-auto">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Page;
